import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
  Polyline
} from "react-leaflet";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import React from "react";
import "./index.css"
import Moment from 'moment';
import { Box, Button, Slider, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Container } from "@mui/system";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { deDE, ruRU } from '@mui/x-date-pickers/locales';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import dayjs from 'dayjs';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from "moment";

class Location {
  Latitude: number = 0;
  Time: string = "";
  Longitude: number = 0;;
}




function App() {
  const [initialPosition, setInitialPosition] = useState<[number, number]>([0, 0]);
  const [selectedPosition, setSelectedPosition] = useState<[number, number]>([0, 0]);
  const [markers, setMarkers] = useState<any[]>([]);
  const [pos, setPos] = useState<any[]>([]);

  var onFilterClick = () => {
    /*   alert('clicked'); */
    debugger
    var a = age;
    var b = value.toString();
    var c = start + ":00:00"
    var d = end + ":00:00"

    const beginDate = moment(b).format('YYYY-MM-DD')

    const beginTime = moment(c).format('YYYY-MM-DD HH:mm:ss')
    fetch("https://courier.avoska-dostavka.ru/api/get_locations?id=" + a + "&date=" + beginDate + "&start=" + c + "&end=" + d)//result[0].UserId)
      .then(res => res.json())
      .then(
        (result) => {
          var res = []
          var pos_ = []
          for (var i = 0; i < result.length; i++) {

            var dt = result[i].Time;
            var dt_formated = Moment(dt).utcOffset('+1000').format('DD.MM.yyyy hh:mm') //basically you can do all sorts of the formatting and others

            var mini_res = new Location();
            mini_res.Latitude = result[i].Latitude
            mini_res.Longitude = result[i].Longitude
            mini_res.Time = dt_formated
            pos_.push({ lat: result[i].Latitude, lng: result[i].Longitude })
            res.push(mini_res)
          }

          setMarkers(res)
          setPos(pos_)
        },
        (error) => {
          //setIsLoaded(true);
          //setError(error);
        }
      )


  }
  /*  const pos:any [] = [
     {lat: 42.844, lng:132.91699},
     {lat: 42.844112, lng:132.91164},
   ]; */

  function getLocationList(id: any) {
    fetch("https://courier.avoska-dostavka.ru/api/get_locations?id=" + id)//result[0].UserId)
      .then(res => res.json())
      .then(
        (result) => {
          var res = []
          var pos_ = []
          for (var i = 0; i < result.length; i++) {

            var dt = result[i].Time;
            var dt_formated = Moment(dt).utcOffset('+1000').format('DD.MM.yyyy hh:mm') //basically you can do all sorts of the formatting and others

            var mini_res = new Location();
            mini_res.Latitude = result[i].Latitude
            mini_res.Longitude = result[i].Longitude
            mini_res.Time = dt_formated
            pos_.push({ lat: result[i].Latitude, lng: result[i].Longitude })
            res.push(mini_res)
          }

          setMarkers(res)
          setPos(pos_)
        },
        (error) => {
          //setIsLoaded(true);
          //setError(error);
        }
      )
  }
  React.useEffect(() => {


    fetch("https://courier.avoska-dostavka.ru/api/get_users")
      .then(res => res.json())
      .then(
        (result) => {
          setList(result)
          getLocationList(result[0].UserId)


        },
        (error) => {
          //setIsLoaded(true);
          //setError(error);
        }
      )



  }, [])



  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      const { latitude, longitude } = position.coords;
      setInitialPosition([latitude, longitude]);

    });
  }, []);

  /*   const Markers = () => {
  
      const map = useMapEvents({
          click(e) {      
            debugger                            
              setSelectedPosition([
                  e.latlng.lat,
                  e.latlng.lng
              ]);                
          },            
      })
  
      return (
          selectedPosition ? 
              <Marker           
              key={selectedPosition[0]}
              position={selectedPosition}
              interactive={false} 
              />
          : null
      )   
      
  } */
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const [age, setAge] = React.useState('');
  const [list, setList] = React.useState([]);

  const [value, setValue] = React.useState<any>();
  const [start, setStart] = React.useState<any>();
  const [end, setEnd] = React.useState<any>();

  const handleChange1 = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
    //getLocationList(event.target.value as string)
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };


  var iconSettings = {
    mapIconUrl: '<svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 178"><path fill="{mapIconColor}" stroke="#FFF" stroke-width="6" stroke-miterlimit="10" d="M126 23l-6-6A69 69 0 0 0 74 1a69 69 0 0 0-51 22A70 70 0 0 0 1 74c0 21 7 38 22 52l43 47c6 6 11 6 16 0l48-51c12-13 18-29 18-48 0-20-8-37-22-51z"/><circle fill="{mapIconColorInnerCircle}" cx="74" cy="75" r="61"/><circle fill="#FFF" cx="74" cy="75" r="{pinInnerCircleRadius}"/></svg>',
    mapIconColor: '#cc756b',
    mapIconColorInnerCircle: '#fff',
    pinInnerCircleRadius: 48
  };




  var iconSettings = {
    mapIconUrl: '<svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 178"><path fill="{mapIconColor}" stroke="#FFF" stroke-width="6" stroke-miterlimit="10" d="M126 23l-6-6A69 69 0 0 0 74 1a69 69 0 0 0-51 22A70 70 0 0 0 1 74c0 21 7 38 22 52l43 47c6 6 11 6 16 0l48-51c12-13 18-29 18-48 0-20-8-37-22-51z"/><circle fill="{mapIconColorInnerCircle}" cx="74" cy="75" r="61"/><circle fill="#FFF" cx="74" cy="75" r="{pinInnerCircleRadius}"/></svg>',
    mapIconColor: '#cc756b',
    mapIconColorInnerCircle: '#fff',
    pinInnerCircleRadius: 48
  };

  var divIcon = L.divIcon({
    className: "leaflet-data-marker",
    html: L.Util.template(iconSettings.mapIconUrl, iconSettings), //.replace('#','%23'),
    iconAnchor: [12, 32],
    iconSize: [25, 30],
    popupAnchor: [0, -28]
  });



  return (



    <Container sx={{ mt: 2 }}>


      <div>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              Фильтры
            </Typography>
            {/*   <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
          </AccordionSummary>
          <AccordionDetails>
            <Box pb={2} style={{ width: '100%' }}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel style={{ width: '100%' }} id="demo-simple-select-label">Идентификатор курьера</InputLabel>
                <Select
                  style={{ width: '100%' }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  label="Идентификатор курьера"
                  onChange={handleChange1}
                >

                  {
                    list.map((x: any) => {
                      return (<MenuItem value={x.UserId}>{x.UserId}</MenuItem>)
                    })
                  }
                  {/*  <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <LocalizationProvider localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText} dateAdapter={AdapterDayjs} style={{ paddingTop: 20, }}>
                <DatePicker sx={{ mt: 2 }}
                  className="test"
                  views={["day"]}
                  label="Дата"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}


                />

                {/*  <DemoContainer
                  components={[
                    'TimePicker',
                    'MobileTimePicker',
                    'DesktopTimePicker',
                    'StaticTimePicker',
                  ]}

                >
                  <DemoItem label="начало">
                    <MobileTimePicker onChange={(newValue) => {
                      setStart(newValue);
                    }} />
                  </DemoItem>

                  <DemoItem label="конец">
                    <MobileTimePicker onChange={(newValue) => {
                      setEnd(newValue);
                    }} />
                  </DemoItem>

                </DemoContainer> */}
              </LocalizationProvider>

              {/*             <TextField
                id="outlined-number"
                label="Number"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0, max: 23 } }}
            
                value={start}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {

                  var value = parseInt(event.target.value, 10);

                  if (value > 23) value = 23;
                  if (value < 0) value = 0;

                  setStart(value);
                }}
              /> */}
              <Typography sx={{mt:2}}> Времня начала</Typography>
              <Slider
                aria-label="Restricted values"

                defaultValue={9}
                onChange={
                  (event: Event, newValue: number | number[]) => {
                    setStart(newValue as number);
                  }
                }
                step={1}
                marks
                min={8}
                max={23}
                valueLabelDisplay="auto"
              />

              <Typography sx={{mt:2}}> Времня окончания</Typography>
              <Slider
                aria-label="Restricted values"

                defaultValue={9}
                onChange={
                  (event: Event, newValue: number | number[]) => {
                    setEnd(newValue as number);
                  }
                }
                step={1}
                marks
                min={8}
                max={23}
                valueLabelDisplay="auto"
              />

              {/*       <TextField
                id="outlined-number"
                label="Number"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0, max: 23 } }}
            

                value={end}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {

                  var value = parseInt(event.target.value, 10);

                  if (value > 23) value = 23;
                  if (value < 0) value = 0;

                  setEnd(value);
                }}

              /> */}
              <Button variant="contained"
                style={{ width: '100%' }}
                onClick={onFilterClick}>Применить</Button>
            </Box>
          </AccordionDetails>
        </Accordion>

      </div>


      <div style={{ display: "flex", flexWrap: 'wrap', marginTop:20 }} > 

        <MapContainer
          style={{
            height: "100vh",
            width: "100%",
          }}
          center={[42.432026740690574, 132.8439179532812]}
          zoom={8}

        >
          {markers.length > 0 &&
            markers.map((marker: Location) => (
              <Marker
                position={[
                  marker.Latitude,
                  marker.Longitude
                ]}
                icon={divIcon}
              >
                <Popup>{marker.Time}</Popup>

              </Marker>
            ))}
          {/* add google map tile url  */}
          {/*   <Markers /> */}
          <Polyline positions={pos} color="rgb(204, 117, 107)" />
          <TileLayer
            attribution='<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
      </div>
    </Container>


  )
    ;
}

export default App;